import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from '@chakra-ui/react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { FaAmbulance } from 'react-icons/fa';
import { FiArrowRight } from 'react-icons/fi';

const AlertButtonModal = ({ ...props }) => {
    const { isOpen, onToggle, onClose } = useDisclosure();
    const { t } = useTranslation();

    return (
        <>
            <Button
                aria-label="Alert button"
                backgroundColor="red.100"
                borderColor="red.200"
                borderRadius="lg"
                borderWidth="2px"
                leftIcon={<FaAmbulance />}
                onClick={onToggle}
                shadow="lg"
                {...props}>
                {t('common:emergency')}
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius="lg">
                    <ModalHeader>{t('common:emergencyAlert')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>{t('common:alertCopy')}</ModalBody>

                    <ModalFooter>
                        <Link href="/alert">
                            <Button
                                onClick={() => onClose()}
                                backgroundColor="tooddoc.500"
                                color="white"
                                rightIcon={<FiArrowRight />}>
                                {t('common:overHere')}
                            </Button>
                        </Link>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AlertButtonModal;
