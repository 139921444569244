import { HStack, IconButton } from '@chakra-ui/react';
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa';

const SocialButton = ({ ...props }) => {
    return (
        <HStack {...props} height="2rem">
            <IconButton
                as="a"
                target="_blank"
                rel="noreferrer"
                size={30}
                href="https://www.facebook.com/tooddoc/"
                aria-label="Facebook link"
                icon={<FaFacebookSquare size={30} />}
            />
            <IconButton
                as="a"
                target="_blank"
                rel="noreferrer"
                size={30}
                href="https://www.linkedin.com/company/tooddoc/"
                aria-label="Facebook link"
                icon={<FaLinkedin size={30} />}
            />
        </HStack>
    );
};

export default SocialButton;
