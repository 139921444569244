import { HStack } from '@chakra-ui/react';

const Header = ({ children, ...props }) => {
    return (
        <HStack
            align="center"
            backgroundColor="rgba(255,255,255, 0.9)"
            boxShadow="sm"
            w={['100%', '100%', 'intial']}
            justify="space-between"
            // position={['fixed', 'fixed', 'sticky']}
            position="sticky"
            px={['1rem', '2rem', '3rem']}
            py={['0.5rem', '1rem']}
            // top={['initial', 'initial', '0']}
            top="0"
            // bottom={['0', '0', 'initial']}
            zIndex={1200}
            borderRadius="lg"
            {...props}>
            {children}
        </HStack>
    );
};

export default Header;
