import { Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';

import FrequentlySearched from '@/components/FrequentlySearched';

const StyledFooter = styled(Stack)`
    content-visibility: auto;
`;

const Footer = ({ ...props }) => {
    return (
        <StyledFooter
            direction={['column', 'column', 'row']}
            alignItems={['flex-start', 'flex-start', 'initial']}
            borderTop="1px solid #EAEAEA"
            paddingY="2rem"
            {...props}>
            <FrequentlySearched />
        </StyledFooter>
    );
};

export default Footer;
