import { IconButton } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FiArrowLeft } from 'react-icons/fi';

const BackButton = ({ ...props }) => {
    const router = useRouter();

    return (
        <IconButton
            icon={<FiArrowLeft />}
            onClick={() => router.back()}
            variant="link"
            size="lg"
            {...props}
        />
    );
};

export default BackButton;
