import { useMemo } from 'react';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

let store;

const customInitialState = {
    practice: null,
    place: null,
    lat: null,
    long: null,
    card: null,
    token: null,
    numberOfCards: 30,
    authUser: null,
    mongoDBUser: null,
    currentPartner: null
};

// REDUCERS
export const reducer = (state = customInitialState, action) => {
    switch (action.type) {
        case 'SET_SEARCH_PARAMETERS':
            return {
                ...state,
                practice: action.practice,
                lat: action.lat,
                long: action.long,
                place: action.place
            };
        case 'SET_TOKEN':
            return {
                ...state,
                token: action.token
            };
        case 'SET_CURRENT_CARD':
            return {
                ...state,
                card: action.card
            };
        case 'ADD_MORE_CARDS':
            return { ...state, numberOfCards: state.numberOfCards + 20 };
        case 'SET_AUTH_USER':
            return { ...state, authUser: action.authUser };
        case 'SET_USER':
            return { ...state, user: action.user };
        case 'SET_CURRENT_PARTNER':
            return { ...state, currentPartner: action.currentPartner };
        default:
            return state;
    }
};

const persistConfig = {
    key: 'primary',
    storage,
    whitelist: [
        'practice',
        'lat',
        'long',
        'card',
        'token',
        'authUser',
        'user',
        'currentPartner',
        'place'
    ] // place to select which state you want to persist
};

const persistedReducer = persistReducer(persistConfig, reducer);

function makeStore(initialState = customInitialState) {
    return createStore(persistedReducer, initialState, composeWithDevTools(applyMiddleware()));
}

export const initializeStore = (preloadedState) => {
    let _store = store ?? makeStore(preloadedState);

    /*
     * After navigating to a page with an initial Redux state, merge that state
     * with the current state in the store, and create a new store
     */
    if (preloadedState && store) {
        _store = makeStore({
            ...store.getState(),
            ...preloadedState
        });
        // Reset the current store
        store = undefined;
    }

    // For SSG and SSR always create a new store
    if (typeof window === 'undefined') return _store;

    // Create the store once in the client
    if (!store) store = _store;

    return _store;
};

export function useStore(initialState) {
    const store = useMemo(() => initializeStore(initialState), [initialState]);

    return store;
}
