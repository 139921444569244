import { theme } from '@chakra-ui/react';

const customIcons = {
    filters: {
        path: (
            <svg fill="none" viewBox="0 0 25 18" xmlns="http://www.w3.org/2000/svg">
                <defs />
                <path
                    d="M1 4h23M1 14h23"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <circle cx="7" cy="4" fill="#fff" r="3" stroke="#000" strokeWidth="2" />
                <circle cx="18" cy="14" fill="#fff" r="3" stroke="#000" strokeWidth="2" />
            </svg>
        ),
        viewBox: '0 0 25 18'
    }
};

export default {
    ...theme,
    colors: {
        ...theme.colors,
        tooddoc: {
            50: '#ddf6ff',
            100: '#b0deff',
            200: '#82c6fb',
            300: '#53aff7',
            400: '#2598f3',
            500: '#0c7fda',
            600: '#0062aa',
            700: '#00467b',
            800: '#002a4d',
            900: '#000f1f'
        }
    },
    icons: {
        ...theme.icons,
        ...customIcons
    }
    /*
     * fonts: {
     *   heading: 'Inter, "Avenir Next", sans-serif',
     *   body: 'Inter, system-ui, sans-serif',
     *   mono: 'Inter, Menlo, monospace'
     * }
     */
};
