import { ChakraProvider } from '@chakra-ui/react';
import { init as SentryInit } from '@sentry/node';
import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import I18nProvider from 'next-translate/I18nProvider';
import NProgress from 'nprogress';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { SWRConfig } from 'swr';

import SiteLayout from '@/components/Layout/SiteLayout';
import * as gtag from '@/utils/gtag';
import theme from '@/utils/theme';

import { useStore } from '../store';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    SentryInit({
        enabled: process.env.NODE_ENV === 'production',
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
    });
}

/*
 * Measuring performance
 * export function reportWebVitals(metric) {
 *   switch (metric.name) {
 *     case 'FCP':
 *       // handle First Contentful Paint results
 *       console.log(
 *         `First Contentful Paint results => ${JSON.stringify(metric)}`
 *       );
 *       break;
 *     case 'LCP':
 *       console.log(
 *         `Largest Contentful Paint results => ${JSON.stringify(metric)}`
 *       );
 *       // handle Largest Contentful Paint results
 *       break;
 *     case 'CLS':
 *       console.log(
 *         `Cumulative Layout Shift results => ${JSON.stringify(metric)}`
 *       );
 *       // handle Cumulative Layout Shift results
 *       break;
 *     case 'FID':
 *       console.log(`First Input Delay results => ${JSON.stringify(metric)}`);
 *       // handle First Input Delay results
 *       break;
 *     case 'TTFB':
 *       console.log(`Time to First Byte results => ${JSON.stringify(metric)}`);
 *       // handle Time to First Byte results
 *       break;
 *     default:
 *       break;
 *   }
 * }
 */

export async function loadNamespaces(namespaces, lang) {
    let res = {};
    for (let ns of namespaces) {
        res[ns] = await import(`../locales/${lang}/${ns}.json`).then((m) => m.default);
    }
    return res;
}

const MyApp = ({ Component, pageProps, err }) => {
    const store = useStore(pageProps.initialReduxState);
    const persistor = persistStore(store);

    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = (url) => {
            gtag.pageview(url);
        };

        let routeChangeStart = () => NProgress.start();
        let routeChangeComplete = () => {
            handleRouteChange();
            NProgress.done();
        };

        Router.events.on('routeChangeStart', routeChangeStart);
        Router.events.on('routeChangeComplete', routeChangeComplete);
        Router.events.on('routeChangeError', routeChangeComplete);
        return () => {
            Router.events.off('routeChangeStart', routeChangeStart);
            Router.events.off('routeChangeComplete', routeChangeComplete);
            Router.events.off('routeChangeError', routeChangeComplete);
        };
    }, []);

    const getLayout = Component.getLayout || ((page) => <SiteLayout>{page}</SiteLayout>);

    return (
        <>
            <Head>
                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/nprogress/0.2.0/nprogress.min.css"
                />
            </Head>
            <ChakraProvider theme={theme}>
                <SWRConfig
                    value={{
                        fetcher: (...args) => fetch(...args).then((res) => res.json())
                    }}>
                    <Provider store={store}>
                        <PersistGate persistor={persistor}>
                            <I18nProvider lang={router.locale} namespaces={pageProps._ns}>
                                {getLayout(<Component err={err} {...pageProps} />)}
                            </I18nProvider>
                        </PersistGate>
                    </Provider>
                </SWRConfig>
            </ChakraProvider>
        </>
    );
};

export default MyApp;
