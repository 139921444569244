import { HStack, IconButton } from '@chakra-ui/react';
import { FaAppStoreIos, FaGooglePlay } from 'react-icons/fa';

const AppStoresButtons = ({ ...props }) => {
    return (
        <HStack {...props} height="2rem">
            <IconButton
                as="a"
                variant="ghost"
                target="_blank"
                rel="noreferrer"
                size={30}
                href="itms-apps://apps.apple.com/be/app/tooddoc/id1331906587?l=fr"
                aria-label="App Store link"
                icon={<FaAppStoreIos size={30} />}
            />
            <IconButton
                as="a"
                variant="ghost"
                target="_blank"
                rel="noreferrer"
                size={30}
                href="https://play.google.com/store/apps/details?id=com.tooddoc.app"
                aria-label="Google Play Store link"
                icon={<FaGooglePlay size={30} />}
            />
        </HStack>
    );
};

export default AppStoresButtons;
