import { HStack, IconButton } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FiSearch, FiUser } from 'react-icons/fi';

import BackButton from '@/components/Buttons/BackButton';
import Header from '@/components/Menu/Header';

import AlertButtonModal from './AlertButtonModal';

const DefaultMenu = ({ ...props }) => {
    const router = useRouter();

    return (
        <Header {...props}>
            <HStack spacing={['1rem', '2rem']}>
                <BackButton />
                <IconButton
                    aria-label="Menu button"
                    backgroundColor="white"
                    borderColor="gray.200"
                    borderRadius="lg"
                    borderWidth="2px"
                    fontSize="xl"
                    icon={<FiSearch />}
                    onClick={() => router.push('/')}
                    shadow="lg"
                    size="md"
                />
            </HStack>

            <HStack>
                <AlertButtonModal size="md" />
                <IconButton
                    aria-label="Menu button"
                    backgroundColor="white"
                    borderColor="gray.200"
                    borderRadius="lg"
                    borderWidth="2px"
                    fontSize="xl"
                    icon={<FiUser />}
                    onClick={() => router.push('/profile')}
                    shadow="lg"
                    size="md"
                />
            </HStack>
        </Header>
    );
};

export default DefaultMenu;
