import { Flex } from '@chakra-ui/react';

import Footer from '@/components/Footer';
import DefaultMenu from '@/components/Menu/DefaultMenu';

const SiteLayout = ({ children }) => {
    return (
        <Flex
            direction="column"
            minHeight="100vh"
            maxWidth={['100vw', '80vw']}
            margin="0 auto"
            height="100%">
            {children.props.defaultMenu && <DefaultMenu />}
            {children}
            <Footer
                mt={['10rem', 'auto']}
                style={{ contentVisibility: 'auto' }}
                px={['2rem', '3rem', '4rem']}
                align="flex-end"
            />
        </Flex>
    );
};

export const getLayout = (page) => <SiteLayout>{page}</SiteLayout>;

export default SiteLayout;
