import { Button, Stack, VStack } from '@chakra-ui/react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';

import AppStoresButtons from '@/components/Buttons/AppStoresButtons';
import SocialButtons from '@/components/Buttons/SocialButtons';

const frequentPractices = [
    { en: 'Dentist', fr: 'Dentiste', nl_BE: 'Tandarts' },
    { en: 'General-practitioner', fr: 'Medecin-generaliste', nl_BE: 'Huisarts' },
    { en: 'Dermatologist', fr: 'Dermatologue', nl_BE: 'Dermatoloog' }
];
const frequentLocations = [
    {
        en: 'Brussels',
        fr: 'Bruxelles',
        nl: 'Brussel',
        de: 'Brussel',
        postcode: '1000',
        lat: '50.84655731',
        long: '4.351697'
    },
    {
        en: 'Antwerp',
        fr: 'Anvers',
        nl: 'Antwerpen',
        de: 'Antwerpen',
        postcode: '2000',
        lat: '51.1391',
        long: '4.62968'
    },
    {
        en: 'Namur',
        fr: 'Namur',
        nl: 'Namen',
        de: 'Namur',
        postcode: '5000',
        lat: 50.45620385,
        long: 4.849314652
    },
    {
        en: 'Liege',
        fr: 'Liege',
        nl: 'Luik',
        de: 'Luttich',
        postcode: '4000',
        lat: 50.6451381,
        long: 5.5734203
    }
];

const FrequentlySearched = ({ ...props }) => {
    const { t, lang } = useTranslation();

    return (
        <Stack
            {...props}
            spacing="2rem"
            direction={['column', 'column', 'row']}
            alignItems={['flex-start', 'flex-start', 'initial']}
            wrap="wrap">
            {frequentPractices.map((practice, index) => (
                <VStack key={index} align="flex-start" direction="column" wrap="wrap">
                    {frequentLocations.map((location) => {
                        const pathname = `/${t(`${practice[lang]}`)?.toLowerCase()}/${location[
                            lang
                        ]?.toLowerCase()}/${location[lang]?.toLowerCase()}/${
                            location.postcode
                        }?lat=${location.lat}&long=${location.long}&radius=${5}`;
                        return (
                            <Link key={pathname} href={pathname}>
                                <Button
                                    fontSize="0.8rem"
                                    mb="0.3rem"
                                    key={location.en}
                                    variant="link">
                                    {`${practice[lang]}`} {t('common:at')} {location[lang]}
                                </Button>
                            </Link>
                        );
                    })}
                </VStack>
            ))}
            <VStack>
                <Link href="/pro" locale={lang}>
                    <Button as="a" fontSize="0.8rem" variant="link">
                        Pro
                    </Button>
                </Link>
                <Link href="/press" locale={lang}>
                    <Button as="a" fontSize="0.8rem" variant="link">
                        Presse
                    </Button>
                </Link>
                <Link href="/contact" locale={lang}>
                    <Button as="a" fontSize="0.8rem" variant="link">
                        Contact
                    </Button>
                </Link>
                <Link href="/legals" locale={lang}>
                    <Button as="a" fontSize="0.8rem" variant="link">
                        Legals
                    </Button>
                </Link>
            </VStack>
            <SocialButtons />
            <AppStoresButtons />
        </Stack>
    );
};

export default FrequentlySearched;
