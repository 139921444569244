export const GA_TRACKING_ID = 'GTM-TZ7F97F';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
    window.gtag('config', GA_TRACKING_ID, {
        page_path: url
    });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
    window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value
    });
};

export const setVar = (vars) => {
    window.gtag('set', vars);
};

export const customEvent = (eventName, parameters) => {
    if (parameters) window.gtag('event', eventName, parameters);
    else window.gtag('event', eventName);
};
